.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  width: 10vmin;
  pointer-events: none;
}

.Underconst{
  height: 10vmin;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.innerWrapper {
  text-align: center;
  width: auto;
}

.image {
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
  height: 'auto';
}
.button:active {
  background-color: grey;
  color: white;
}
.button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.embed {
  display: table; 
  width: 100%;
  height: 100vh; 
  overflow:hidden;
  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
