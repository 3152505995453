body {
    font-family: Arial, sans-serif;
    background-color: #000;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    color: #fff;
}

.login-container {
    /* border: 2px solid #fff; */
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: 300px;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.login-box h2 {
    margin-bottom: 20px;
    color: #fff;
}

.input-group {
    margin-bottom: 15px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    color: #bbb;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #333;
    color: #fff;
}

.login-wrapper {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    /* animation: pulse 2s infinite; */
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.login-button:hover {
    background-color: #0056b3;
}

.error-message {
    margin-top: 10px;
    color: #ff4d4d;
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
